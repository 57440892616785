import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { doLogin, verifyAuthCode } from 'actions/accountActions';
import NavBar from 'components/NavBar';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import SecureLogo from 'images/secure-logo.png';
import './Login.scss';

const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    doLogin: login => {
        dispatch(doLogin(login));
    },
    verifyAuthCode: (userId, code) => {
        dispatch(verifyAuthCode(userId, code));
    },
});

const IS_STAGE = window.location.hostname === 'stage.moneyswell.com';

class Login extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        doLogin: PropTypes.func,
        login: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            login: {},
            userId: null,
            totp_step: false,
            totp_code: '',
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            login: nextProps.account.login,
            userId: (nextProps.account.login?.userId || this.state.userId),
            totp_step: nextProps.account.login.totp_enabled,
        });
    }

    changeEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    changePassword = (e) => {
        this.setState({ password: e.target.value });
    }

    changeTotpCode = (e) => {
        this.setState({ totp_code: e.target.value });
    }

    submitLogin = (e) => {
        e.preventDefault();

        if (this.state.email === '') {
            alert('Email cannot be empty.');
            return false;
        } else if (this.state.password === '') {
            alert('Password cannot be empty.');
            return false;
        }

        this.props.doLogin({
            email: this.state.email,
            password: this.state.password,
        });

        return false;
    }

    submit2fa = (e) => {
        e.preventDefault();

        if (this.state.totp_code === '') {
            alert('Two-factor code cannot be empty.');
            return false;
        }

        this.props.verifyAuthCode(
            this.state.userId,
            this.state.totp_code,
        );

        return false;
    }

    closeSnackbar = () => {
        this.setState({ login: {} });
    }

    render() {
        const { email, password, login, totp_step, totp_code } = this.state;

        let alert = null;
        if (login.success) {
            alert = { severity: 'success', msg: login.success.msg };
        } else if (login.error) {
            alert = { severity: 'error', msg: login.body.msg + ' (' + login.status + ')' };
        }

        let content = (
            <div className="main login">
                <h1>Login</h1>
                <div className="form-wrapper">
                    <form name="login" onSubmit={this.submitLogin}>
                        <h2>Congratulations! You're one step closer to building better financial wellness. Please login to continue.</h2>

                        <fieldset>
                            <label htmlFor="email">Email Address</label>
                            <input type={IS_STAGE ? 'text' : 'email'}
                                id="email"
                                autoFocus
                                placeholder="Email Address"
                                autoComplete="email"
                                value={email}
                                onChange={this.changeEmail} />
                        </fieldset>

                        <fieldset>
                            <label htmlFor="password">Password</label>
                            <input type="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={this.changePassword} />
                        </fieldset>

                        <input type="submit" className="primary" value="Log In" />
                    </form>

                    <div className="forgot-password">
                        <a href="/forgotPassword">Forgot Password?</a>
                    </div>

                    <img src={SecureLogo} alt="Badge confirming site uses an SSL secure connection" />
                </div>
            </div>
        );
        if (totp_step) {
            content = (
                <div className="main login">
                    <h1>Two-factor authentication</h1>
                    <p>Please enter your 6-digit two-factor authentication code to log in.</p>
                    <form name="verify_totp" onSubmit={this.submit2fa}>
                        <input type="text"
                            className="text totp"
                            autoFocus
                            placeholder="123456"
                            value={totp_code}
                            onChange={this.changeTotpCode} />
                        <input type="submit" className="primary totp" value="Verify code" />
                    </form>
                </div>
            );
        }

        return (
            <div className="wrapper">
                <NavBar />

                {content}

                <Footer />

                {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
