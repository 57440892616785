import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactPasswordStrength from 'react-password-strength';
import { saveSignup } from 'actions/accountActions';
import StandardAlert from 'components/StandardAlert';
import Footer from 'components/Footer';
import { MIN_PASSWORD_LENGTH } from 'constants/config';
import Logo from 'images/icons/logo.svg';
import './Signup.scss';

const IS_BENEFIT = (window.location.pathname === '/benefit/signup');

const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    saveSignup: signup => {
        dispatch(saveSignup(signup));
    }
});

class Signup extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        saveSignup: PropTypes.func,
        success: PropTypes.bool,
        signup: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            accessCode: '',
            firstName: '',
            email: '',
            password: {password: ''},
            passwordResult: null,
            confirm: '',
            signup: {},
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            signup: nextProps.account.signup,
        });
    }

    updateState = (e, key) => {
        let state = {};
        state[key] = e.target.value;
        this.setState(state);
    }

    changePassword = (state, result) => {
        this.setState({password: state, passwordResult: result});
    }

    submitSignup = (e) => {
        e.preventDefault();

        const pwObj = this.state.password;
        const feedback = this.state.passwordResult?.feedback;

        if (this.state.email === '') {
            alert('Email cannot be empty.');
            return false;
        } else if (this.state.firstName === '') {
            alert('First name cannot be empty.');
            return false;
        } else if (IS_BENEFIT && this.state.accessCode === '') {
            alert('Access code cannot be empty.');
            return false;
        } else if (pwObj.password === '') {
            alert('Password cannot be empty.');
            return false;
        } else if (pwObj.password.length < MIN_PASSWORD_LENGTH) {
            alert(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`);
            return false;
        } else if (!pwObj.isValid) {
            let msg = feedback.warning;
            if (feedback.suggestions && feedback.suggestions.length > 0) {
                msg += ` - ${feedback.suggestions[0]}`;
            }
            alert(msg);
            return false;
        } else if (this.state.confirm === '') {
            alert('Confirm Password cannot be empty.');
            return false;
        } else if (pwObj.password !== this.state.confirm) {
            alert('Passwords do not match.');
            return false;
        }

        this.props.saveSignup({
            accessCode: this.state.accessCode,
            firstName: this.state.firstName,
            email: this.state.email,
            password: pwObj.password,
            confirm: this.state.confirm,
            userData: sessionStorage.moneyswell,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        return false;
    }

    closeSnackbar = () => {
        this.setState({ signup: {} });
    }

    render() {
        const { accessCode, firstName, email, password, confirm, signup } = this.state;

        let alert = null;
        if (signup.success) {
            alert = { severity: 'success', msg: signup.success.msg };
        } else if (signup.error) {
            alert = { severity: 'error', msg: signup.body.msg + ' (' + signup.status + ')' };
        }

        let header = 'Sign Up';
        let mainText = (<h2>Start with a <b>34 day free trial. No credit card required.</b> When your trial ends, you can sign up for a monthly or annual plan, or let your account expire.</h2>);
        let cta = 'Start My Free Trial';
        if (IS_BENEFIT) {
            header = 'Benefit Sign Up';
            mainText = (
                <>
                    <h2>Congratulations! You've been given access to a MoneySwell account!</h2><br />
                    <h2>To access your benefit, enter the access code you were provided with. Remember, <b>your account is private.</b> Your
                    benefit provider will not have any visibility into your account data.</h2>
                </>
            );
            cta = 'Create My Account';
        }
        return (
            <div className="wrapper">
                <div className="login-signup-header">
                    <div className="subhead">
                        <a href="https://www.moneyswell.com"><img src={Logo} className="logo" alt="MoneySwell logo" /></a>
                        <a href="/login" className="primary">Log In</a>
                    </div>
                    <h1>{header}</h1>
                </div>

                <div className={cn('main signup', IS_BENEFIT && 'benefit')}>
                    {mainText}

                    <p>Already have an account? <a href="/login">Log in</a>.</p>

                    <div className="form-wrapper">
                        <form name="signup" onSubmit={this.submitSignup}>
                            {IS_BENEFIT &&
                                <fieldset className="first">
                                    <label for="accessCode">Enter Provided Access Code</label>
                                    <input type="text"
                                        id="accessCode"
                                        autoFocus
                                        placeholder="Access Code"
                                        value={accessCode}
                                        onChange={(e) => this.updateState(e, 'accessCode')} />
                                </fieldset>
                            }

                            <fieldset>
                                <input type="text"
                                    id="firstName"
                                    autoFocus={!IS_BENEFIT}
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => this.updateState(e, 'firstName')} />
                            </fieldset>

                            <fieldset>
                                <input type="email"
                                    id="email"
                                    placeholder="Email Address"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => this.updateState(e, 'email')} />
                            </fieldset>

                            <fieldset>
                                <ReactPasswordStrength
                                    minLength={MIN_PASSWORD_LENGTH}
                                    minScore={2}
                                    scoreWords={['Weak', 'Okay', 'Good', 'Strong', 'Strongest']}
                                    tooShortWord='Too Short'
                                    changeCallback={this.changePassword}
                                    inputProps={{ id: 'password', placeholder: 'Password', autoComplete: 'off', value: password }}
                                />
                            </fieldset>

                            <fieldset>
                                <input type="password"
                                    id="confirm"
                                    placeholder="Confirm Password"
                                    value={confirm}
                                    onChange={(e) => this.updateState(e, 'confirm')} />
                            </fieldset>

                            <p>By creating an account, you agree to MoneySwell's <a href="https://www.moneyswell.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                            &nbsp;and <a href="https://www.moneyswell.com/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a>.</p>

                            <input type="submit" className="primary" value={cta} />
                        </form>
                    </div>
                </div>

                <Footer />

                {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
