import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verifyAccount } from 'actions/accountActions';
import NavBar from 'components/NavBar';
import Loading from 'components/Loading';
import Footer from 'components/Footer';

const mapStateToProps = state => ({
    verification: state.account.verification,
});

const mapDispatchToProps = dispatch => ({
    verifyAccount: (code) => {
        dispatch(verifyAccount(code));
    },
});

class VerifyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            verified: false,
        };
    }

    componentDidMount() {
        this.props.verifyAccount(window.location.search.split('=')[1]);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        if (nextProps.verification) {
            state.loaded = true;
            state.verified = !!nextProps.verification.success;
        }

        this.setState(state);
    }

    render() {
        const { loaded, verified } = this.state;

        if (!loaded) {
            return (<Loading />);
        } else {
            let content = (
                <>
                    <h1>Thank you for verifying your account!</h1>
                    <p><a href="/login">Log in now</a></p>
                </>
            );
            if (!verified) {
                content = (
                    <>
                        <h1>Verification failed</h1>
                        <p>We're sorry, we could not verify your account. Please contact support.</p>
                    </>
                );
            }

            return (
                <div className="wrapper">
                    <NavBar />

                    <div className="main verification">
                        {content}
                    </div>

                    <Footer />
                </div>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
